.resultsList {
	display: grid;
	grid-template-columns: 1fr;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
	grid-gap: 16px;
	grid-column: 1 / -1;

	@media (--medium) {
		grid-gap: 24px;
		grid-template-columns: 1fr 1fr;
		grid-column: 5 / -1;
	}

	@media (--large) {
		grid-gap: 32px;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column: 4 / -1;
	}
}

.singleColumn {
	grid-template-columns: 1fr;
}

.result {
	display: flex;
}
