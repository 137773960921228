.noResults {
	padding: 64px;
}

.heading {
	composes: g-type-display-4 from global;
	margin: 0;
	padding-bottom: 8px;
}

.text {
	color: var(--wpl-neutral-500);
	margin: 0;
	padding-bottom: 8px;
}
