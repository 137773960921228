.pagination {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	list-style: none;
	padding: 0;
}

.paginationResult {
	border-radius: 2px;
	display: flex;
	margin: 2px;

	& button {
		background-color: #f7f8fa;
		border: none;
		color: #373a42;
		cursor: pointer;
		font-size: 14px;
		height: 100%;
		margin: 0;
		padding: 10px 12px;

		&:disabled {
			color: #8e96a3;
			cursor: not-allowed;
		}
	}
}

.ellipsis {
	composes: paginationResult;

	& button {
		background: none;
		border: none;

		&:disabled {
			cursor: initial;
		}
	}
}

.activeResult {
	border-bottom: 2px solid var(--brand);
	composes: paginationResult;

	& button {
		background-color: transparent;
		color: var(--brand);
		font-weight: bold;
	}
}

.paginationWrapper {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 40px;

	@media (--medium-up) {
		flex-direction: row;
	}
}

.left,
.right {
	color: #6f7682;
	flex: 1;
	font-size: 14px;
}

.right {
	display: none;
	@media (--medium-up) {
		display: block;
	}
}

.paginationButton {
	padding: 9.5px 24px !important;

	&:disabled {
		background-color: transparent;
	}

	&:not(:disabled) {
		&::after,
		&::before {
			color: var(--black);
			font-weight: bold;
		}
	}
}

.paginationButtonLeft {
	composes: paginationButton;

	&::before {
		content: '‹';
		margin-right: 10px;
	}
}

.paginationButtonRight {
	composes: paginationButton;

	&::after {
		content: '›';
		margin-left: 10px;
	}
}
